<script>
import { ArrowUpIcon, ShieldIcon, CpuIcon, VideoIcon,ArrowRightIcon, ArrowRightCircleIcon,ChevronRightIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Index-classic-saas component
 */
export default {
  data() {
    return {
    };
  },
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    Carousel,
    Slide,
    countTo,
    ShieldIcon,
    CpuIcon,
    VideoIcon,
    ArrowRightIcon, 
    ArrowRightCircleIcon,
    ChevronRightIcon
  },
};
</script>

<template>
  <div>
    <Navbar />
    <!-- Hero Start -->
    <section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
      <div class="container">
        <div class="row align-items-center pt-5">
          <div class="col-lg-7 col-md-6">
            <div class="title-heading">
              <h1 class="heading mb-3">
                Grow with OTAs, <br />
                boost efficiency
              </h1>
              <p class="para-desc text-muted">
                Access the largest distribution network, extend your reach and boost <br /> revenue with TREEP Channel Manager.
              </p>
              <div class="mt-4 pt-2">
                <a href="https://treep.cc/demo/?channelType=1"
                  target="_blank"
                  class="btn btn-primary"
                  >Book a meeting</a>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="classic-saas-image position-relative">
              <div class="bg-saas-shape position-relative">
                <img
                  src="images/channel/channel_1.png" height="594"
                  class="mx-auto d-block"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Partners start -->
    <section class="pt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 order-1 order-md-2">
            <img src="images/template/task.svg" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div
            class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
          >
            <div class="section-title mr-lg-5">
              <h1 class="text-primary"><i class="uil uil-list-ui-alt"></i></h1>
              <h4 class="title mb-4">
                What is Channel Manager?
              </h4>
              <p class="text-muted">
                It's the powerful tool that connects suppliers and resellers via API connection. Distribute rates and inventory to your chosen distribution channels – from major OTA's, oversea and local resellers to  agencies. 
              </p>
              <ul class="list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  >Update in real time, sync two-way, process automatically.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  >Unlock new revenue streams for all parties involved.
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

    </section>
    <!--end section-->
    <!-- Partners End -->

    <!-- Start -->
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Enjoy the benefits of partnering with TREEP</h4>
              <p class="para-desc text-muted mb-0">
                To partner with the right businesses and grow your business in multipule ways.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div
              class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow" style="height:335px"
            >
              <div class="icons text-primary text-center">
                <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
              </div>
              <div class="card-body p-0 mt-4">
                <a href="javascript:void(0)" class="title h5 text-dark"
                  >Increased visibility</a
                >
                <p class="text-muted mt-2">
                  Connect with partners who can expand your business futher than your own website to new audiences, helping you reach new markets and regions.
                </p>
                <i class="uil uil-layer-group text-primary full-img"></i>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div
              class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow" style="height:335px"
            >
              <div class="icons text-primary text-center">
                <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
              </div>
              <div class="card-body p-0 mt-4">
                <a href="javascript:void(0)" class="title h5 text-dark"
                  >Partner relationships</a
                >
                <p class="text-muted mt-2">
                  Build long-term relationships with other businesses in the tourism industry. Create more opportunities for future collaborations and growth.
                </p>
                <i class="uil uil-airplay text-primary full-img"></i>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div
              class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow" style="height:335px"
            >
              <div class="icons text-primary text-center">
                <i class="uil uil-focus-target d-block rounded h3 mb-0"></i>
              </div>
              <div class="card-body p-0 mt-4">
                <a href="javascript:void(0)" class="title h5 text-dark"
                  >Cost effective marketing</a
                >
                <p class="text-muted mt-2">
                  Let your distribution partners promote your tours to their customers. Reduce your marketing costs and advertising spend.
                </p>
                <i class="uil uil-focus-target text-primary full-img"></i>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 mt-4 pt-2">
            <div
              class="card border-0 features feature-clean course-feature p-4 overflow-hidden shadow" style="height:335px"
            >
              <div class="icons text-primary text-center">
                <i class="uil uil-expand-arrows d-block rounded h3 mb-0"></i>
              </div>
              <div class="card-body p-0 mt-4">
                <a href="javascript:void(0)" class="title h5 text-dark"
                  >Enhanced guest experience</a
                >
                <p class="text-muted mt-2">
                  Provide your customers with a seamless travel experience from booking to the end of their tour, no matter which OTA they book on.
                </p>
                <i class="uil uil-expand-arrows text-primary full-img"></i>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Discover some of our key partners</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                TREEP partners with the most prominent distribution platforms and online travel agencies.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/klook.jpg" height="60"></div>
                <p class="text-muted">Asia's largest in-destination services booking system with a network of over 5,000 direct partners.</p>
                <a href="https://www.klook.cn/zh-CN/" target="_blank" class="text text-primary">
                  Visit Klook<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/trip.jpg" height="60"></div>
                <p class="text-muted">China's largest online travel agency with over a million travel products both locally and internationally.</p>
                <a href="https://hk.trip.com/?locale=zh-hk" target="_blank" class="text text-primary">
                  Visit Trip.com<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/meituan.jpg" height="60"></div>
                <p class="text-muted">A "local life" service platform best-known for its on-demand food delivery service.</p>
                <a href="https://www.meituan.com/" target="_blank" class="text text-primary">
                  Visit Meituan<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/fliggy.jpg" height="60"></div>
                <p class="text-muted">A Chinese travel platform that provides services such as flight and hotel bookings, travel packages, and car rentals.</p>
                <a href="https://www.fliggy.com/" target="_blank" class="text text-primary">
                  Visit Fliggy<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/qunar.jpg" height="60"></div>
                <p class="text-muted">A Chinese travel metasearch engine that provides thorough and instant search of flights, hotels, packages, group-buying deals, visa, and other travel-related information.</p>
                <a href="https://www.qunar.com/" target="_blank" class="text text-primary">
                  Visit Qunar<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/lazycat.jpg" height="60"></div>
                <p class="text-muted">Lazy Cat Travel is a travel company that offers  travel services for Chinese tourists visiting Southeast Asia.</p>
                <a href="https://lmlxhwd.fliggy.com/" target="_blank" class="text text-primary">
                  Visit Lazy Cat Travel<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/gyg.jpg" height="60"></div>
                <p class="text-muted">A Berlin-based online platform for booking tours, attractions, and activities worldwide.</p>
                <a href="https://www.getyourguide.com/" target="_blank" class="text text-primary">
                  Visit GetYourGuide<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/booking.jpg" height="60"></div>
                <p class="text-muted">An online travel agency for lodging reservations with over 28 million listings.</p>
                <a href="https://www.booking.com/" target="_blank" class="text text-primary">
                  Visit Booking.com<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/expedia.jpg" height="60"></div>
                <p class="text-muted">An American online travel agency that provides hotel reservations, airline tickets, car rentals, and vacation packages.</p>
                <a href="https://www.expedia.com/" target="_blank" class="text text-primary">
                  Visit Expedia<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/traveloka.jpg" height="60"></div>
                <p class="text-muted">A Southeast Asian online travel company that provides a wide range of travel needs in one platform.</p>
                <a href="https://www.traveloka.com/" target="_blank" class="text text-primary">
                  Visit Traveloka<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/headout.jpg" height="60"></div>
                <p class="text-muted">A travel app that helps travelers discover and book the best experiences in their destination city.</p>
                <a href="https://www.headout.com/" target="_blank" class="text text-primary">
                  Visit Headout<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow text-center" style="height:320px">
              <div class="card-body" style="display:flex;flex-direction: column;justify-content: center;">
                <div class="text-center m-4"><img src="images/partners/makemytrip.jpg" height="60"></div>
                <p class="text-muted">An Indian online travel company that provides a wide range of travel services, including flight tickets, hotel bookings, and vacation packages.</p>
                <a href="https://www.makemytrip.com/" target="_blank" class="text text-primary">
                  Visit MakeMyTrip<arrow-right-icon class="fea icon-sm ml-2"></arrow-right-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
            <div class="rounded bg-primary p-lg-5 p-4">
                <div class="row align-items-end">
                    <div class="col-md-8">
                        <div class="section-title text-md-left text-center">
                            <h4 class="title mb-3 text-white title-dark">Join TREEP's partner distribution network and start growing your business today</h4>
                            <p class="text-white-50 mb-0">Extend your reach and boost revenue. </p>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="text-md-right text-center">
                            <a href="https://treep.cc/demo/?channelType=1" target="_blank" class="btn btn-light">Book a meeting</a>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->


    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
